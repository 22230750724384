import { TextField, Grid, Typography } from '@mui/material';
import { yupErrorText } from '../utils';

const Applications = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography gutterBottom pb={4}>
          Please enter an email to manage your job posting. This email will not
          be publicly displayed. You will receive a link in the email to manage
          and edit your job posting. <span><strong>Your job will not appear on Seasoned Force
          until you publish it via the link.</strong></span>
        </Typography>
        <TextField
          label="Admin Email"
          variant="outlined"
          name="adminEmail"
          fullWidth
          value={formik.values.adminEmail}
          onChange={formik.handleChange}
          error={formik.touched.adminEmail && Boolean(formik.errors.adminEmail)}
          helperText={
            formik.touched.adminEmail && formik.errors.adminEmail
              ? yupErrorText(formik.errors.adminEmail)
              : ''
          }
        />
      </Grid>
    </>
  );
};

export default Applications;
