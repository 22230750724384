import React, { useState, useEffect } from 'react';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Container from 'components/Container';

interface Props {
  children: React.ReactNode;
}

const Page = ({ children }: Props): JSX.Element => {
  //   const [activeLink, setActiveLink] = useState('');
  //   useEffect(() => {
  //     setActiveLink(window && window.location ? window.location.pathname : '');
  //   }, []);

  //   const theme = useTheme();

  return (
    <Box>
      <Box bgcolor={'primary.main'} paddingY={4}>
        <Container>
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ color: 'common.white' }}
          >
            Post your job - $49
          </Typography>
          <Typography variant="h6" sx={{ color: 'common.white' }}>
            Post will last 60 days
          </Typography>
        </Container>
      </Box>
      <Container paddingTop={'0 !important'} marginTop={-8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Card sx={{ boxShadow: 3 }}>
              <Box padding={3}>
                <Typography
                  variant={'subtitle2'}
                  color={'text.secondary'}
                  gutterBottom
                >
                  When saved, <span><strong>you will receive an email</strong></span> with a link where you
                  can pay for, publish to the site, and edit your job.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>{children}</Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Page;
