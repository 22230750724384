import { useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from 'react-query';
import { listPosts } from 'api';

import { JobFilters, JobItem } from './components';

const Jobs = (): JSX.Element => {
  const [queryString, setQueryString] = useState('');
  console.log('queryString', queryString);
  const { data, isLoading } = useQuery(['posts', queryString], () =>
    listPosts(queryString),
  );

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          align={'center'}
          color={'text.secondary'}
          sx={{ textTransform: 'uppercase' }}
          variant={'subtitle2'}
          fontWeight={600}
        >
          Open positions
        </Typography>
        <Typography fontWeight={700} variant={'h4'} align={'center'}>
          Current job openings
        </Typography>
      </Box>
      <Box marginBottom={4}>
        <JobFilters setQueryString={setQueryString} />
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          borderRadius: 2,
        }}
      >
        {isLoading
          ? () => <CircularProgress />
          : data
            ? data.map((item) => <JobItem item={item} />)
            : null}
      </Grid>
    </Box>
  );
};

export default Jobs;
