import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
// import { useTheme } from '@mui/material/styles';
import Logo from 'assets/images/seasoned_force_logo_dark.png';
import { Link as RouterLink } from 'react-router-dom';

const Footer = (): JSX.Element => {
  // const theme = useTheme();
  // const { mode } = theme.palette;
  const geo = localStorage.getItem('geo');

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          <Box
            display={'flex'}
            component="a"
            href="/"
            title="seasonedForce"
            width={80}
          >
            <Box component={'img'} src={Logo} height={1} width={1} />
          </Box>
          <Box display="flex" flexWrap={'wrap'} alignItems={'center'}>
            {geo ? (
              <Box marginTop={1} marginRight={2}>
                <Typography
                  variant={'subtitle2'}
                  color="text.primary"
                  onClick={() => {
                    localStorage.removeItem('geo');
                    window.location.reload();
                  }}
                  sx={{ cursor: 'pointer' }}
                >
                  Clear my location
                </Typography>
              </Box>
            ) : null}
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component="a"
                href={
                  'mailto:seasonedforce@gmail.com?subject=Question about Seasoned Force'
                }
                target={'blank'}
                color="text.primary"
                variant={'subtitle2'}
              >
                Contact
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component={RouterLink}
                to={'/privacy-policy'}
                color="text.primary"
                variant={'subtitle2'}
              >
                Privacy Policy
              </Link>
            </Box>
            <Box marginTop={1} marginRight={2}>
              <Link
                underline="none"
                component={RouterLink}
                to={'/terms-of-use'}
                color="text.primary"
                variant={'subtitle2'}
              >
                Terms of Use
              </Link>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; Seasoned Force 2024. All rights reserved.
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          When you visit or interact with our site, services or tools, we or our
          authorised service providers may use cookies for storing information
          to help provide you with a better, faster and safer experience and for
          marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
