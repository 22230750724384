import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RichText from 'components/RichText';

const Application = ({ job }): JSX.Element => {
  return (
    <Box maxWidth={800} margin={'0 auto'}>
      <Box marginBottom={2}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          gutterBottom
          align={'center'}
        >
          How do I apply?
        </Typography>

        <RichText object={job.instructions} />
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          component="a"
          target="_blank"
          rel="noopener"
          href={
            job.applicationUrl
              ? job.applicationUrl
              : `mailto:${job.applicationEmail}?subject=${job.position} job inquiry`
          }
        >
          Apply now
        </Button>
      </Box>
    </Box>
  );
};

export default Application;
