import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import GoogleMaps from 'components/GooglePlaceAutoComplete';

export const AddLocationButton = ({ buttonText = 'Add Your Location' }) => {
  const [open, setOpen] = useState(false);
  const [latLng, setLatLng] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSelect = (location) => {
    const latLng = {
      lat: location.lat,
      lng: location.lng,
    };
    setLatLng(latLng);
  };

  const handleSavleLocation = () => {
    const json = JSON.stringify(latLng);
    localStorage.setItem('geo', json);
    handleClose();
    window.location.reload();
  };

  return (
    <>
      <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        {buttonText}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Set your location</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" pb={4}>
            Add your location to filter on jobs near you and see distance. You
            can use your actual address to get better results. Your location is
            stored securely in your local browser and not shared with anyone.
          </DialogContentText>
          <GoogleMaps handleSelect={handleSelect} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSavleLocation} autoFocus variant="contained">
            Save Location
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLocationButton;
