import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';
import { yupErrorText } from '../utils';
import GooglePlaceField from 'components/GooglePlaceAutoComplete';

const JobParameters = ({ formik }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          error={formik.touched.isFlexible && Boolean(formik.errors.isFlexible)}
        >
          <InputLabel id="scheduling-label">Scheduling *</InputLabel>
          <Select
            labelId="scheduling-label"
            id="scheduling"
            name="isFlexible"
            value={
              formik.values.isFlexible === true
                ? 'true'
                : formik.values.isFlexible === false
                  ? 'false'
                  : ''
            }
            label="Scheduling *"
            onChange={(e) => {
              const value = e.target.value;
              formik.handleChange(e);
              formik.setFieldValue(
                'isFlexible',
                value === 'true' ? true : value === 'false' ? false : null,
              );
            }}
          >
            <MenuItem value="" sx={{ opacity: 0.6 }}>
              <em>Select scheduling type</em>
            </MenuItem>
            <MenuItem value="true">Flexible</MenuItem>
            <MenuItem value="false">Fixed</MenuItem>
          </Select>
          {formik.touched.isFlexible && formik.errors.isFlexible && (
            <Typography variant="caption" color="error">
              {yupErrorText(formik.errors.isFlexible)}
            </Typography>
          )}
        </FormControl>
      </Grid>

      {/* Work Environment */}
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          error={formik.touched.isSocial && Boolean(formik.errors.isSocial)}
        >
          <InputLabel id="atmosphere-label">Work Environment *</InputLabel>
          <Select
            labelId="atmosphere-label"
            id="atmosphere"
            name="isSocial"
            value={
              formik.values.isSocial === true
                ? 'true'
                : formik.values.isSocial === false
                  ? 'false'
                  : ''
            }
            onChange={(e) => {
              const value = e.target.value;
              formik.handleChange(e);
              formik.setFieldValue(
                'isSocial',
                value === 'true' ? true : value === 'false' ? false : null,
              );
            }}
            label="Work Environment *"
          >
            <MenuItem value="" sx={{ opacity: 0.6 }}>
              <em>Select environment</em>
            </MenuItem>
            <MenuItem value="true">Social</MenuItem>
            <MenuItem value="false">Independent</MenuItem>
          </Select>
          {formik.touched.isSocial && formik.errors.isSocial && (
            <Typography variant="caption" color="error">
              {yupErrorText(formik.errors.isSocial)}
            </Typography>
          )}
        </FormControl>
      </Grid>

      {/* Activity Level */}
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          error={
            formik.touched.activityLevel && Boolean(formik.errors.activityLevel)
          }
        >
          <InputLabel id="activity-level-label">Activity Level *</InputLabel>
          <Select
            labelId="activity-level-label"
            id="activity-level"
            name="activityLevel"
            value={formik.values.activityLevel}
            label="Activity Level *"
            onChange={formik.handleChange}
          >
            <MenuItem value="" sx={{ opacity: 0.6 }}>
              <em>Select activity level</em>
            </MenuItem>
            <MenuItem value="low">Low</MenuItem>
            <MenuItem value="med">Medium</MenuItem>
            <MenuItem value="high">High</MenuItem>
          </Select>
          {formik.touched.activityLevel && formik.errors.activityLevel && (
            <Typography variant="caption" color="error">
              {yupErrorText(formik.errors.activityLevel)}
            </Typography>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          error={formik.touched.isRemote && Boolean(formik.errors.isRemote)}
        >
          <InputLabel id="location-label">Onsite or Remote *</InputLabel>
          <Select
            labelId="location-label"
            id="location"
            name="isRemote"
            value={
              formik.values.isRemote === true
                ? 'true'
                : formik.values.isRemote === false
                  ? 'false'
                  : ''
            }
            label="Onsite or Remote *"
            onChange={(e) => {
              const value = e.target.value;
              formik.handleChange(e);
              formik.setFieldValue(
                'isRemote',
                value === 'true' ? true : value === 'false' ? false : null,
              );
            }}
          >
            <MenuItem value="">
              <em>Select location</em>
            </MenuItem>
            <MenuItem value={'false'}>Onsite</MenuItem>
            <MenuItem value={'true'}>Remote</MenuItem>
          </Select>
          {formik.touched.isRemote && formik.errors.isRemote && (
            <Typography variant="caption" color="error">
              {yupErrorText(formik.errors.isRemote)}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={8}>
        <GooglePlaceField
          handleSelect={(location) => {
            console.log(location);
            formik.setFieldValue('location', location);
          }}
          label="Job Location"
          disabled={formik.values.isRemote !== false}
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={
            formik.errors.location
              ? yupErrorText(formik.errors.location)
              : 'Where the job will be performed. Exact location will not be shown publicly.'
          }
          address={formik.values.location?.formatted_address || ''}
        />
      </Grid>
    </>
  );
};

export default JobParameters;
