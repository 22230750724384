import React, { useCallback, useMemo } from 'react';
import isHotkey from 'is-hotkey';
import { Editable, withReact, Slate, useSlate } from 'slate-react';
import { createEditor, Editor, Transforms } from 'slate';
import { withHistory } from 'slate-history';

import Box from '@mui/material/Box';
import ToggleButton from '@mui/material/ToggleButton';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import TitleIcon from '@mui/icons-material/Title';
// import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const HOTKEYS = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

const emptyEditorValue = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

const RichEditor = ({
  value,
  setValue,
  label,
  placeholder = '',
  error,
  helperText,
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  return (
    <Grid container>
      <Grid item xs={12} sm={4}>
        <Box
          mb={1}
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'flex-end',
          }}
        >
          <Typography variant="subtitle2" fontWeight={700}>
            {label}
          </Typography>
        </Box>
      </Grid>

      <Slate
        editor={editor}
        initialValue={value || emptyEditorValue}
        onChange={setValue}
      >
        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: { xs: 'flex-start', sm: 'flex-end' },
            }}
          >
            <Toolbar>
              <MarkButton format="bold">
                <FormatBoldIcon />
              </MarkButton>
              <MarkButton format="italic">
                <FormatItalicIcon />
              </MarkButton>
              <MarkButton format="underline">
                <FormatUnderlinedIcon />
              </MarkButton>
              <BlockButton format="heading-one">
                <TitleIcon />
              </BlockButton>
              {/* <BlockButton format="heading-two">
              <SubjectIcon />
            </BlockButton> */}
              {/* <BlockButton format="block-quote">
                <FormatQuoteIcon />
              </BlockButton> */}
              <BlockButton format="numbered-list">
                <FormatListNumberedIcon />
              </BlockButton>
              <BlockButton format="bulleted-list">
                <FormatListBulletedIcon />
              </BlockButton>
            </Toolbar>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box
            border={1}
            borderColor="divider"
            borderRadius={1}
            p={1}
            sx={{
              borderColor: error ? 'error.main' : 'divider',
              '&:hover': {
                borderColor: error ? 'error.main' : 'black',
              },
              '&:focus-within': {
                borderColor: error ? 'error.main' : 'primary.main',
                borderWidth: 2,
              },
              '& [data-slate-editor="true"]': {
                outline: 'none',
                boxShadow: 'none',
              },
              '& [data-slate-editor="true"]:focus': {
                outline: 'none',
                boxShadow: 'none',
              },
            }}
          >
            <Editable
              renderElement={renderElement}
              renderLeaf={renderLeaf}
              placeholder={placeholder}
              spellCheck
              autoFocus
              onKeyDown={(event) => {
                for (const hotkey in HOTKEYS) {
                  if (isHotkey(hotkey, event)) {
                    event.preventDefault();
                    const mark = HOTKEYS[hotkey];
                    toggleMark(editor, mark);
                  }
                }
              }}
            />
          </Box>
          {helperText && (
            <Typography
              variant="caption"
              color={error ? 'error.main' : 'text.secondary'}
            >
              {helperText}
            </Typography>
          )}
        </Grid>
      </Slate>
    </Grid>
  );
};

export const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

export const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const buttonStyle = {
  lineHeight: 1,
  marginRight: '0.25rem',
  padding: '0.25rem',
  minWidth: 'unset',
};

const BlockButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      size="small"
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
      sx={buttonStyle}
    >
      {children}
    </ToggleButton>
  );
};

const MarkButton = ({ format, children }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      size="small"
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
      sx={buttonStyle}
    >
      {children}
    </ToggleButton>
  );
};

const Menu = React.forwardRef(({ children, ...props }, ref) => (
  <>
    <Box
      display="flex"
      direction="row"
      justify="flex-start"
      alignItems="center"
      flexWrap="wrap"
      pb={1}
      ref={ref}
      {...props}
    >
      {children}
    </Box>
  </>
));

const Toolbar = React.forwardRef(({ ...props }, ref) => (
  <Menu {...props} ref={ref} />
));

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });
  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export default RichEditor;
