import { useTheme } from '@mui/material/styles';
import { Box, Button, Typography, Grid, Stack } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-router-dom';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
TimeAgo.addDefaultLocale(en);
const timeAgo = new TimeAgo('en-US');

const JobItem = ({ item }) => {
  const theme = useTheme();
  const showTime = false;

  return (
    <Grid item xs={12} key={item.id}>
      <Box
        padding={2}
        display={'flex'}
        alignItems={'center'}
        sx={{
          background: theme.palette.background.paper,
          borderRadius: 2,
          borderBottom: `1.5px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', sm: 'row' }}
          flex={'1 1 100%'}
          justifyContent={{ sm: 'space-between' }}
          alignItems={{ sm: 'center' }}
        >
          <Box marginBottom={{ xs: 1, sm: 0 }}>
            <Typography variant={'subtitle1'} fontWeight={700}>
              {item.position}
            </Typography>
            <Typography color={'text.secondary'}>{item.company}</Typography>
            {showTime && (
              <Typography color={'text.secondary'} sx={{ fontSize: '60%' }}>
                {item.createdAt ? timeAgo.format(new Date(item.createdAt)) : ''}
              </Typography>
            )}
          </Box>
          <Typography color={'text.secondary'}>
            {item.isRemote ? 'Remote' : `${item.city}, ${item.state}`}
          </Typography>
        </Box>
        <Stack marginLeft={2} direction={'row'}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            component="a"
            target="_blank"
            rel="noopener"
            href={
              item.applicationUrl
                ? item.applicationUrl
                : `mailto:${item.applicationEmail}?subject=${item.position} job inquiry`
            }
          >
            Apply
          </Button>
          <Button component={Link} to={`/jobs/${item.id}`}>
            <ChevronRightIcon />
          </Button>
        </Stack>
      </Box>
    </Grid>
  );
};

export default JobItem;
