import { useEffect, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import AddLocationButton from './AddLocationButton';
import { SearchContext } from 'context';
// import { update } from 'lodash';

const JobFilters = ({ setQueryString }) => {
  const theme = useTheme();
  const { query, updateQuery } = useContext(SearchContext);

  useEffect(() => {
    let string = '?';
    const geo = localStorage.getItem('geo');
    if (!!query.jobTypes && !!query.jobTypes.length) {
      string += `jobTypes=${query.jobTypes.join(',')}&`;
    }
    if (query.schedule !== '') {
      string += `isFlexible=${query.schedule === 'flexible'}&`;
    }
    if (query.environment !== '') {
      string += `isSocial=${query.environment === 'social'}&`;
    }
    if (!!query.activityLevels && !!query.activityLevels.length) {
      string += `activityLevel=${query.activityLevels.join(',')}&`;
    }

    if (!!query.distance && !!geo) {
      const obj = JSON.parse(geo);
      string += `distance=${query.distance}&geo=${`${obj.lat},${obj.lng}`}&`;
    }
    setQueryString(string);
  }, [query, setQueryString]);

  const handleJobTypesChange = (event) => {
    const values = event.target.value;
    updateQuery('jobTypes', values);
  };
  const handleActivityLevelsChange = (event) => {
    const values = event.target.value;
    updateQuery('activityLevels', values);
  };

  return (
    <Grid
      container
      spacing={4}
      sx={{
        '.MuiOutlinedInput-root': {
          background: theme.palette.background.paper,
        },
      }}
    >
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" sx={{ minWidth: 1 }}>
          <InputLabel id="career-listing__jobs-role--label">
            Job Type
          </InputLabel>
          <Select
            labelId="career-listing__jobs-role--label"
            label="Job Type"
            multiple
            value={query.jobTypes}
            onChange={handleJobTypesChange}
          >
            {/* <MenuItem value="">
              <em>All types</em>
            </MenuItem> */}
            <MenuItem value={'partTime'}>Part Time</MenuItem>
            <MenuItem value={'fullTime'}>Full Time</MenuItem>
            <MenuItem value={'contract'}>Contract</MenuItem>
            <MenuItem value={'volunteer'}>Volunteer</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" sx={{ minWidth: 1 }}>
          <InputLabel id="career-listing__jobs-role--label">
            Schedule
          </InputLabel>
          <Select
            labelId="career-listing__jobs-role--label"
            label="Schedule"
            value={query.schedule}
            onChange={(event) => {
              // setSchedule(event.target.value);
              updateQuery('schedule', event.target.value);
            }}
          >
            <MenuItem value="">
              <em>Any</em>
            </MenuItem>
            <MenuItem value={'flexible'}>Flexible Schedule</MenuItem>
            <MenuItem value={'fixed'}>Fixed Hours</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" sx={{ minWidth: 1 }}>
          <InputLabel id="career-listing__jobs-role--label">
            Work Environment
          </InputLabel>
          <Select
            labelId="career-listing__jobs-role--label"
            label="Work Environment"
            value={query.environment}
            onChange={(event) => {
              // setEnvironment(event.target.value);
              updateQuery('environment', event.target.value);
            }}
          >
            <MenuItem value="">
              <em>All</em>
            </MenuItem>
            <MenuItem value={'social'}>Social</MenuItem>
            <MenuItem value={'independant'}>Independent</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={3}>
        <FormControl variant="outlined" sx={{ minWidth: 1 }}>
          <InputLabel id="career-listing__jobs-role--label">
            Activity Level
          </InputLabel>
          <Select
            labelId="career-listing__jobs-role--label"
            label="Activity Level"
            multiple
            value={query.activityLevels}
            onChange={handleActivityLevelsChange}
          >
            {/* <MenuItem value="">
              <em>Any</em>
            </MenuItem> */}
            <MenuItem value={'low'}>Low</MenuItem>
            <MenuItem value={'med'}>Medium</MenuItem>
            <MenuItem value={'high'}>High</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} textAlign={'center'}>
        {localStorage.getItem('geo') ? (
          <TextField
            label="Distance (in miles)"
            variant="outlined"
            fullWidth
            sx={{ maxWidth: 300 }}
            value={query.distance}
            error={isNaN(Number(query.distance))}
            onChange={(event) => {
              // setDistance(event.target.value);
              updateQuery('distance', event.target.value);
            }}
            helperText={
              isNaN(Number(query.distance)) ? 'Please enter a valid number' : ''
            }
          />
        ) : (
          <AddLocationButton buttonText="Add your location to see jobs near you" />
        )}
      </Grid>
    </Grid>
  );
};

export default JobFilters;
