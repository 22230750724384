import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { getPostDetails } from 'api';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Main from 'layouts/Main';
import Container from 'components/Container';
import { Main as MainSection, Application } from './components';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const JobView = () => {
  const { jobId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(['job', jobId], () =>
    getPostDetails(jobId),
  );

  if (isLoading) {
    return <Box>Loading...</Box>;
  }

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <Main>
      <Container>
        <Box display="flex" justifyContent="flex-start" mb={3}>
          <Button
            onClick={handleBack}
            startIcon={<ChevronLeftIcon sx={{ fontSize: 32 }} />}
            sx={{
              textTransform: 'none',
              backgroundColor: 'transparent',
              color: 'text.primary',
              padding: 0,
              minWidth: 'auto',
              fontSize: 24,
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            Back
          </Button>
        </Box>
        <MainSection job={data} />
      </Container>
      <Box bgcolor={'alternate.main'} sx={{ textAlign: 'center' }}>
        <Container>
          <Application job={data} />
        </Container>
      </Box>
    </Main>
  );
};

export default JobView;
