import { useQuery, useMutation } from 'react-query';
import { editPost, updatePost } from 'api';
import { useParams } from 'react-router-dom';
import JobForm from 'components/JobForm';
import { formatJobForEditing } from 'components/JobForm/utils';
import Container from 'components/Container';
import { Box, Card, Grid, Typography, Button } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';

const EditJob = () => {
  const { editJobId } = useParams();
  const { data, isLoading, refetch } = useQuery(
    ['editPost', editJobId],
    () => editPost(editJobId),
    {
      enabled: !!editJobId,
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
      refetchOnReconnect: false,
    },
  );

  const { mutate, isLoading: isSubmitting } = useMutation(updatePost, {
    onSuccess: () => {
      enqueueSnackbar('Job updated! ', {
        variant: 'success',
      });
      refetch();
    },
    onError: (error) => {
      console.error('Error updating job: ', error);
      enqueueSnackbar(
        'There was an error updating the job. Please check form and try again',
        {
          variant: 'error',
        },
      );
    },
  });

  const handleSubmit = (values: any) => {
    mutate({ data: values, id: editJobId });
  };
  console.log('DATA: ', data);

  if (isLoading) return <div>Loading...</div>;
  if (!data) return <div>Not Found</div>;
  if (data.error) return <div>Error: {data.error}</div>;
  const formatDate = (date: string) => {
    const d = new Date(date);
    return d.toLocaleDateString();
  };

  return (
    <Box>
      <Box bgcolor={'primary.main'} paddingY={4}>
        <Container>
          <Typography
            variant="h4"
            fontWeight={700}
            gutterBottom
            sx={{ color: 'common.white' }}
          >
            Seasoned Force
          </Typography>
          <Typography variant="h6" sx={{ color: 'common.white' }}>
            Edit your job post
          </Typography>
        </Container>
      </Box>
      <Container paddingTop={'0 !important'} marginTop={-8}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={3}>
            <Card sx={{ boxShadow: 3 }}>
              <Box padding={3} textAlign={'center'}>
                {data?.paymentSession?.url ? (
                  <>
                    <Typography variant="h4">$49</Typography>
                    <Typography mb={2} sx={{ opacity: 0.6 }}>
                      2 month posting
                    </Typography>
                    <Button
                      variant="contained"
                      color="primary"
                      component="a"
                      href={data.paymentSession.url}
                      target="_self"
                      fullWidth
                    >
                      Pay & Publish
                    </Button>
                  </>
                ) : (
                  <>
                    <Typography mb={2}>
                      Posting expires on: {formatDate(data.expiresAt)}
                    </Typography>
                    {data.verified ? (
                      <LoadingButton
                        variant="contained"
                        color="warning"
                        onClick={() => {
                          mutate({
                            data: { verified: false },
                            id: editJobId,
                          });
                        }}
                        fullWidth
                        loading={isSubmitting}
                      >
                        Unpublish
                      </LoadingButton>
                    ) : (
                      <LoadingButton
                        variant="contained"
                        color="success"
                        onClick={() => {
                          mutate({
                            data: { verified: true },
                            id: editJobId,
                          });
                        }}
                        fullWidth
                        loading={isSubmitting}
                      >
                        Publish
                      </LoadingButton>
                    )}
                  </>
                )}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card sx={{ boxShadow: 3, padding: 4 }}>
              <JobForm
                handleSubmit={handleSubmit}
                isLoading={isSubmitting}
                data={formatJobForEditing(data)}
                isEdit
              />
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default EditJob;
