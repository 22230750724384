import { Typography } from '@mui/material';

const RichText = ({ object }) => {
  if (typeof object === 'string') {
    return <Typography>{object}</Typography>;
  }
  return (
    <>
      {object.map((node) => (
        <Node node={node} />
      ))}
    </>
  );
};
export default RichText;

const Node = ({ node }) => {
  switch (node.type) {
    case 'paragraph':
      return <Paragraph node={node} />;
    case 'heading-one':
      return <H1 node={node} />;
    case 'heading-two':
      return <H2 node={node} />;
    case 'block-quote':
      return <Quote node={node} />;
    case 'numbered-list':
      return <NumberedList node={node} />;
    case 'bulleted-list':
      return <BulletedList node={node} />;

    default:
      return null;
  }
};

const pb = 2;

const Paragraph = ({ node }) => {
  return (
    <Typography pb={pb}>
      {node.children.map((child) => (
        <Span child={child} />
      ))}
    </Typography>
  );
};

const Span = ({ child }) => {
  const style: any = {};

  if (child.bold) {
    style.fontWeight = 'bold';
  }

  if (child.italic) {
    style.fontStyle = 'italic';
  }

  if (child.underline) {
    style.textDecoration = 'underline';
  }

  if (Object.keys(style).length) {
    return <span style={style}>{child.text}</span>;
  }

  return child.text;
};

const H1 = ({ node }) => {
  return (
    <Typography variant={'h6'} fontWeight={'bold'} gutterBottom>
      {node.children.map((child) => (
        <Span child={child} />
      ))}
    </Typography>
  );
};

const H2 = ({ node }) => {
  return (
    <Typography variant={'h6'} fontWeight={'bold'} gutterBottom>
      {node.children.map((child) => (
        <Span child={child} />
      ))}
    </Typography>
  );
};

const Quote = ({ node }) => {
  return (
    <blockquote>
      <Typography pb={pb}>
        {node.children.map((child) => (
          <Span child={child} />
        ))}
      </Typography>
    </blockquote>
  );
};

const NumberedList = ({ node }) => {
  return (
    <ol style={{ paddingBottom: pb }}>
      {node.children.map((child) => (
        <li>
          <Typography>
            {child.children.map((item) => (
              <Span child={item} />
            ))}
          </Typography>
        </li>
      ))}
    </ol>
  );
};

const BulletedList = ({ node }) => {
  console.log(node);
  return (
    <ul style={{ paddingBottom: pb }}>
      {node.children.map((child, index) => (
        <li key={index}>
          <Typography>
            {child.children.map((item) => (
              <Span child={item} />
            ))}
          </Typography>
        </li>
      ))}
    </ul>
  );
};
