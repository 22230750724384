import * as yup from 'yup';
import { useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';

import { Grid, Typography, Box, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { formatJobForSaving } from './utils';

import CompanyAndPosition from './components/CompanyAndPosition';
import JobParameters from './components/JobParameters';
import Compensation from './components/Compensation';
import Applications from './components/Applications';
import CompanyDetails from './components/CompanyDetails';
import { EMAIL_REGEX, URL_REGEX } from '../../constants';

const locationValidation = {
  is: false,
  then: (schema) =>
    schema.required('Please enter the job location for onsite jobs'),
};

const validationSchema = yup.object({
  // Company & Position
  company: yup.string().trim().required('Please specify the company name'),
  type: yup.string().required('Please specify the job type'),
  position: yup.string().trim().required('Please specify the position'),
  description: yup
    .array()
    // .of(
    //   yup.object().shape({
    //     type: yup.string().required(),
    //     children: yup.array().of(
    //       yup.object().shape({
    //         text: yup.string().required(),
    //       }),
    //     ),
    //   }),
    // )
    .min(1, 'Please enter the job description')
    .required('Please enter the job description'),

  // Job Details
  activityLevel: yup.string().required('Please specify the activity level'),
  isFlexible: yup
    .boolean()
    .required('Please specify if the job is flexible or not'),
  isSocial: yup.boolean().required('Please specify the work environment'),
  isRemote: yup
    .boolean()
    .required('Please specify if the job is remote or onsite'),
  location: yup.mixed().when('isRemote', locationValidation),

  // Compensation
  compStyle: yup.string().required('Please select a compensation type'),
  minCompensation: yup
    .number()
    .positive('Minimum pay must be a positive number')
    .required('Please enter the minimum pay'),
  maxCompensation: yup
    .number()
    .positive('Maxiumum pay must be a positive number'),

  // Application
  emailOrUrl: yup
    .string()
    .required('Please enter an email or URL')
    .test(
      'email-or-url',
      'Please enter a valid email or URL that begins with https://',
      (value) => {
        return EMAIL_REGEX.test(value) || URL_REGEX.test(value);
      },
    ),
  instructions: yup.string(),
  adminEmail: yup.string().email('Invalid email').required('Required'),
});

const initialValues = {
  // Company & Position
  company: '',
  type: '',
  position: '',
  description: null,

  // Job Details
  isFlexible: null,
  isSocial: null,
  activityLevel: '',
  isRemote: null,
  location: '',

  // Compensation
  compStyle: '',
  minCompensation: '',
  maxCompensation: '',

  // Application
  emailOrUrl: '',
  instructions: '',
  adminEmail: '',
};

const gridSpacing = 4;

const JobForm = ({
  handleSubmit,
  isLoading = false,
  data = null,
  isEdit = false,
}) => {
  const formik = useFormik({
    initialValues: data || initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = formatJobForSaving(values);
      handleSubmit(data);
    },
    validateOnBlur: true,
  });

  console.log('FORMIK: ', formik);

  useEffect(() => {
    if (formik.isSubmitting && !formik.isValid) {
      console.log('submitting form');
      enqueueSnackbar('Please fix the errors in the form', {
        variant: 'error',
      });
    }
  }, [formik.isSubmitting, formik.isValid]);

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box>
          <SectionTitle title="Company & Position" />
          <Grid container spacing={gridSpacing}>
            <CompanyAndPosition formik={formik} />
          </Grid>
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Box>
          <SectionTitle title="Job Parameters" />
          <Grid container spacing={gridSpacing}>
            <JobParameters formik={formik} />
          </Grid>
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Box>
          <SectionTitle title="Compensation" />
          <Grid container spacing={gridSpacing}>
            <Compensation formik={formik} />
          </Grid>
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Box>
          <SectionTitle title="Applications" />
          <Grid container spacing={gridSpacing}>
            <Applications formik={formik} />
          </Grid>
        </Box>
        {!isEdit && (
          <>
            <Box paddingY={4}>
              <Divider />
            </Box>
            <Box>
              <SectionTitle title="Post Management" />
              <Grid container spacing={gridSpacing}>
                <CompanyDetails formik={formik} />
              </Grid>
            </Box>
          </>
        )}
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Box>
          <Grid container spacing={4}>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                margin={'0 auto'}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                  {!isEdit && (
                    <Typography variant={'subtitle2'}>
                      <span><strong>You will receive an email</strong></span> with a link to pay for, post, and edit your job.
                    </Typography>
                  )}
                </Box>
                <LoadingButton
                  size={'large'}
                  variant={'contained'}
                  type="submit"
                  disabled={isLoading}
                >
                  Save
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Box>
  );
};

export default JobForm;

const SectionTitle = ({ title }) => (
  <Typography variant="h6" gutterBottom fontWeight={700} pb={2}>
    {title}
  </Typography>
);
