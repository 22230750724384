import Main from 'layouts/Main';
import Container from 'components/Container';

const TermsOfUse = () => {
  return (
    <Main>
      <Container>
        <div className="terms">
          <h1>Terms of Use</h1>
          <p>
            By using Seasoned Force, you acknowledge and agree to the following:
          </p>
          <p>
            Seasoned Force is not an employment agency. Seasoned Force does not
            have authority to act or make employment decisions on behalf of
            employers or job seekers utilizing the site. There is no
            employer-employee relationship or any agency relationship between
            Seasoned Force and employers or job seekers using the site. Seasoned
            Force is a tool that allows employers to advertise their job
            openings on our site to job seekers. The site is not an offer of
            employment in any way with Seasoned Force. While Seasoned Force may
            be compensated by employers who post job postings, which helps keep
            the site free for job seekers, all job postings are simply
            advertising. Job postings are created and provided by third parties
            over whom Seasoned Force exercises no control; you acknowledge and
            understand that Seasoned Force has no control over the content of
            job postings, or any conditions third parties might impose once a
            job seeker has submitted an application, contacted an employer, or
            left our site. If you leave our site and choose to enter a
            third-party website, you accept any terms and conditions imposed by
            that third-party.
          </p>
          <p>
            Seasoned Force has no obligation to screen any job posting on the
            site, or to include any particular job postings in its search
            results or other listings, and may exclude or remove any job posting
            from our site or your search result without any obligation to
            provide reasoning for removal or exclusion. You understand and agree
            that Seasoned Force has no obligation to present you with any or all
            job postings. We cannot confirm the accuracy or completeness of any
            job posting or other information submitted by any employer or any
            other user, including the identity of such employer or other user.
            Seasoned Force assumes no responsibility, and disclaims all
            liability for the content, accuracy, completeness, legality,
            reliability, or availability of any job posting, or other
            information submitted by any employer or other user.
          </p>
          <p>
            When you use the site as an employee or representative of an
            employer, you represent and warrant that you have the authority to
            bind the employer to these terms of use, that all information you
            provide in regards to an employer or a particular job opening are
            accurate (including the identity of the employer), and any external
            link included with a job posting leads to the employer’s website or
            a legitimate location for submitting applications for that
            particular job posting, or that any email provided to facilitate
            applications by job seekers are the email address of a legitimate
            representative of the employer. Seasoned Force reserves the right to
            remove any job posting or prevent any user from utilizing the site
            if there is any question, in Seasoned Force’s sole discretion, as to
            the identity of the employer or user, the accuracy of the
            information provided by the user, the appropriateness of any
            external links or email addresses provided, or the intentions of any
            user’s use of the site.
          </p>
          <p>
            Seasoned Force accepts no liability and will not be liable for any
            claim, loss, costs, or damages arising from your use of this site,
            arising from any communication or interaction (or attempted
            communication or interaction) between employers and job seekers, or
            the hiring or non-hiring of any job seeker by any employer using the
            site.
          </p>
          <p>
            Any dispute arising out of or in connection with this agreement or
            related in any way to the site will be governed as to all matters,
            including, but not limited to the validity, construction and
            performance of this agreement, by and under the laws of the State of
            Minnesota. You also hereby waive any right to a jury trial in
            connection with any action or litigation in any way arising out of
            or related to your use of the site or these terms of use.
          </p>
        </div>
      </Container>
    </Main>
  );
};

export default TermsOfUse;
