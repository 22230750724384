import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import { ThemeProvider } from '@mui/material/styles';
import getTheme from './theme';
import Paper from '@mui/material/Paper';
import CssBaseline from '@mui/material/CssBaseline';
import { QueryClient, QueryClientProvider } from 'react-query';
import { APIProvider as GoogleMapsApiProvider } from '@vis.gl/react-google-maps';
import { SnackbarProvider } from 'notistack';
import ReactGA from 'react-ga4';
import { trackPageView } from 'tracking';
import { SearchContext } from 'context';
import { DEFAULT_QUERY } from 'constants/index';

const queryClient = new QueryClient();

if (process.env.REACT_APP_ENVIRONMENT === 'PRODUCTION') {
  ReactGA.initialize('G-L05SSDZH3F');
}

function App() {
  const [themeMode, setTheme] = useState('light');
  const [query, setQuery] = useState(DEFAULT_QUERY);

  const updateQuery = (key, value) => {
    setQuery({
      ...query,
      [key]: value,
    });
  };

  useEffect(() => {
    trackPageView();
  }, []);

  const setMode = (mode: string) => {
    try {
      window.localStorage.setItem('themeMode', mode);
    } catch {
      /* do nothing */
    }

    setTheme(mode);
  };
  const themeToggler = (): void => {
    themeMode === 'light' ? setMode('dark') : setMode('light');
  };
  return (
    <SearchContext.Provider value={{ query, updateQuery }}>
      <SnackbarProvider>
        <GoogleMapsApiProvider
          apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        >
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={getTheme(themeMode, themeToggler)}>
              <CssBaseline />
              <Paper elevation={0}>
                <BrowserRouter>
                  <Routes />
                </BrowserRouter>
              </Paper>
            </ThemeProvider>
          </QueryClientProvider>
        </GoogleMapsApiProvider>
      </SnackbarProvider>
    </SearchContext.Provider>
  );
}

export default App;
