import axios from 'axios';
const url = process.env.REACT_APP_API_URL;

export const createPost = async (body) => {
  const { data } = await axios.post(url + '/job-posts', body);
  return data;
};

export const updatePost = async (params) => {
  const { data } = await axios.patch(
    url + '/job-posts/' + params.id,
    params.data,
  );
  return data;
};

export const listPosts = async (queryString) => {
  const { data } = await axios.get(url + '/job-posts' + queryString);
  return data;
};

export const editPost = async (id) => {
  const { data } = await axios.get(
    url + '/edit/jobs/' + id + `?pageUrl=${window.location}`,
  );
  return data;
};

export const getPostDetails = async (id) => {
  const { data } = await axios.get(url + '/job-posts/' + id);
  return data;
};
