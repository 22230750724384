import { TextField, Grid } from '@mui/material';
import { yupErrorText } from '../utils';

const Applications = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          label="Email or URL"
          variant="outlined"
          name="emailOrUrl"
          fullWidth
          value={formik.values.emailOrUrl}
          onChange={formik.handleChange}
          error={formik.touched.emailOrUrl && Boolean(formik.errors.emailOrUrl)}
          helperText={
            formik.touched.emailOrUrl && formik.errors.emailOrUrl
              ? yupErrorText(formik.errors.emailOrUrl)
              : 'Enter an email address or URL for applicants to apply'
          }
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          label="Application Instructions"
          variant="outlined"
          name="instructions"
          fullWidth
          multiline
          rows={4}
          value={formik.values.instructions}
          onChange={formik.handleChange}
          error={
            formik.touched.instructions && Boolean(formik.errors.instructions)
          }
          helperText={
            formik.touched.instructions && formik.errors.instructions
              ? yupErrorText(formik.errors.instructions)
              : ''
          }
        />
      </Grid>
    </>
  );
};

export default Applications;
