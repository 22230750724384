// import React, { useState } from 'react';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
// import AppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import Drawer from '@mui/material/Drawer';
// import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import Main from 'layouts/Main';
import Container from 'components/Container';
import JobsList from 'components/JobsList';

import { Hero, Advantages } from './components';

const Home = (): JSX.Element => {
  const theme = useTheme();
  // const [openBottombar, setOpenBottombar] = useState(false);

  // const handleBottombarOpen = (): void => {
  //   setOpenBottombar(true);
  // };

  // const handleBottombarClose = (): void => {
  //   setOpenBottombar(false);
  // };

  return (
    <Main>
      <Hero />
      <Box
      //   bgcolor={theme.palette.alternate.main}
      >
        <Container>
          <Advantages />
        </Container>
      </Box>
      <Divider />
      <Box bgcolor={'alternate.main'} id="home-job-list">
        <Container maxWidth={1000}>
          <JobsList />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default Home;
