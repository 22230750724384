import { Routes as ReactRoutes, Route } from 'react-router-dom';
import { Home, Job, PostJob, EditJob, PrivacyPolicy, TermsOfUse } from 'views';

const Routes = () => {
  return (
    <ReactRoutes>
      <Route path="/" element={<Home />} />
      <Route path="/jobs/:jobId" element={<Job />} />
      <Route path="/post-job" element={<PostJob />} />
      <Route path="/edit/job/:editJobId" element={<EditJob />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
    </ReactRoutes>
  );
};

export default Routes;
