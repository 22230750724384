import Page from './components/Page';
import Main from 'layouts/Main';
import { useMutation } from 'react-query';
import { createPost } from 'api';
import { enqueueSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

import JobForms from 'components/JobForm';

const JobForm = (): JSX.Element => {
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(createPost, {
    onSuccess: () => {
      enqueueSnackbar('Job saved! Please check your email', {
        variant: 'success',
      });
      navigate('/', { replace: true });
    },
  });

  const handleSubmit = (values: any) => {
    mutate(values);
  };

  return (
    <Main>
      <Page>
        <JobForms handleSubmit={handleSubmit} isLoading={isLoading} />
      </Page>
    </Main>
  );
};

export default JobForm;
