import { useEffect } from 'react';
import { Map, useMap } from '@vis.gl/react-google-maps';

const GoogleMap = ({ job }) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
  const map = useMap();
  if (!apiKey) {
    console.error('REACT_APP_GOOGLE_MAPS_API_KEY is not set');
    return null;
  }
  const mapId = job.id;
  const { lat, lng } = job;
  if (!lat || !lng) {
    console.error('Latitude or Longitude is not set');
    return null;
  }

  useEffect(() => {
    if (map) {
      const circle = new google.maps.Circle({
        strokeColor: 'rgba(255, 0, 0, 0.5)',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: 'rgba(255, 0, 0, 0.2)',
        fillOpacity: 0.35,
        map: map,
        center: { lat, lng },
        radius: 1000,
      });

      return () => {
        circle.setMap(null); // Clean up the circle when the component unmounts
      };
    }
  }, [map]);

  return (
    <Map
      style={{ width: '100%', height: '100%' }}
      defaultCenter={{ lat, lng }}
      defaultZoom={12}
      gestureHandling={'greedy'}
      disableDefaultUI={true}
      mapId={mapId}
    />
  );
};

export default GoogleMap;
