import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import RichTextEditor from '../../RichTextEditor';
import { yupErrorText } from '../utils';

const CompanyAndPosition = ({ formik }) => {
  return (
    <>
      <Grid item xs={12}>
        <TextField
          label="Company Name *"
          variant="outlined"
          name={'company'}
          fullWidth
          value={formik.values.company}
          onChange={formik.handleChange}
          error={formik.touched.company && Boolean(formik.errors.company)}
          helperText={
            formik.touched.company && formik.errors.company
              ? yupErrorText(formik.errors.company)
              : 'Your company name brand/trade name: without Inc., LLC, etc.'
          }
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          error={formik.touched.type && Boolean(formik.errors.type)}
        >
          <InputLabel id="job-type-label">Job Type *</InputLabel>
          <Select
            labelId="job-type-label"
            id="job-type"
            name="type"
            value={formik.values.type}
            label="Job Type *"
            onChange={formik.handleChange}
          >
            <MenuItem value="">
              <em>Select job type</em>
            </MenuItem>
            <MenuItem value="fullTime">Full Time</MenuItem>
            <MenuItem value="partTime">Part Time</MenuItem>
            <MenuItem value="contract">Contract</MenuItem>
            <MenuItem value="temporary">Temporary</MenuItem>
            <MenuItem value="volunteer">Volunteer</MenuItem>
          </Select>
          {formik.touched.type && formik.errors.type && (
            <Typography variant="caption" color="error">
              {yupErrorText(formik.errors.type)}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={8}>
        <TextField
          label="Position *"
          variant="outlined"
          name={'position'}
          fullWidth
          value={formik.values.position}
          onChange={formik.handleChange}
          error={formik.touched.position && Boolean(formik.errors.position)}
          helperText={
            formik.touched.position
              ? yupErrorText(formik.errors.position)
              : 'Please specify as single job position like "Accountant" or "Retail salesperson", not a sentence like "Looking for help with non-profit".'
          }
        />
      </Grid>
      <Grid item xs={12}>
        <RichTextEditor
          value={formik.values.description}
          setValue={(value) => formik.setFieldValue('description', value)}
          label="Job Description *"
          placeholder="Describe the job in detail. Include responsibilities, qualifications, benefits, and perks."
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={
            formik.touched.description &&
            yupErrorText(formik.errors.description)
          }
        />
      </Grid>
    </>
  );
};

export default CompanyAndPosition;
