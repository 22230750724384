import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { yupErrorText } from '../utils';
import CurrencyInput from 'react-currency-input-field';

const Compensation = ({ formik }) => {
  return (
    <>
      <Grid item xs={12} sm={4}>
        <FormControl
          fullWidth
          error={formik.touched.compStyle && Boolean(formik.errors.compStyle)}
        >
          <InputLabel id="compensation-type-label">
            Compensation Type
          </InputLabel>
          <Select
            labelId="compensation-type-label"
            id="compStyle"
            name="compStyle"
            value={formik.values.compStyle}
            label="Compensation Type"
            onChange={formik.handleChange}
          >
            <MenuItem value="">
              <em>Select compensation type</em>
            </MenuItem>
            <MenuItem value="hourly">Hourly</MenuItem>
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="year">Yearly</MenuItem>
          </Select>
          {formik.touched.compStyle && formik.errors.compStyle && (
            <Typography variant="caption" color="error">
              {yupErrorText(formik.errors.compStyle)}
            </Typography>
          )}
        </FormControl>
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          label="Minimum Pay"
          variant="outlined"
          fullWidth
          value={formik.values.minCompensation}
          error={
            formik.touched.minCompensation &&
            Boolean(formik.errors.minCompensation)
          }
          helperText={
            formik.touched.minCompensation && formik.errors.minCompensation
              ? yupErrorText(formik.errors.minCompensation)
              : 'Minimum is required by law'
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputComponent: CurrencyInput,
            inputProps: {
              name: 'minCompensation',
              decimalsLimit: 2,
              decimalScale: 2,
              allowDecimals: true,
              allowNegativeValue: false,
              onValueChange: (value, name) => {
                formik.setFieldValue(name, value);
              },
            },
          }}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          label="Maximum Pay"
          variant="outlined"
          fullWidth
          value={formik.values.maxCompensation}
          error={
            formik.touched.maxCompensation &&
            Boolean(formik.errors.maxCompensation)
          }
          helperText={
            formik.touched.maxCompensation && formik.errors.maxCompensation
              ? yupErrorText(formik.errors.maxCompensation)
              : ''
          }
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            inputComponent: CurrencyInput,
            inputProps: {
              name: 'maxCompensation',
              decimalsLimit: 2,
              decimalScale: 2,
              allowDecimals: true,
              allowNegativeValue: false,
              onValueChange: (value, name) => {
                formik.setFieldValue(name, value);
              },
            },
          }}
        />
      </Grid>
    </>
  );
};

export default Compensation;
