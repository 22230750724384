import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import ListItem from '@mui/material/ListItem';
// import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
// import Avatar from '@mui/material/Avatar';
import GoogleMap from 'components/GoogleMap';
import {
  camelCaseToWords,
  jobActivityLevelDisplay,
  calculateDistance,
} from 'utils';

import RichText from 'components/RichText';

const Main = ({ job }) => {
  const geo = localStorage.getItem('geo');
  const [distance, setDistance] = useState(null);

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  useEffect(() => {
    if (geo && !job.isRemote) {
      const { lat, lng } = JSON.parse(geo);
      const jobLocation = { lat: job.lat, lng: job.lng };
      const userLocation = { lat, lng };
      setDistance(
        calculateDistance(
          jobLocation.lat,
          jobLocation.lng,
          userLocation.lat,
          userLocation.lng,
        ),
      );
    }
  }, [geo, job]);

  console.log('job', job);

  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
            {job.position}
          </Typography>
          <Typography variant={'h6'}>
            {job.company} ·{' '}
            {job.isRemote ? 'Remote' : `${job.city}, ${job.state}`}{' '}
          </Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component="a"
            target="_blank"
            rel="noopener"
            href={
              job.applicationUrl
                ? job.applicationUrl
                : `mailto:${job.applicationEmail}?subject=${job.position} job inquiry`
            }
          >
            Apply now
          </Button>
          <Box marginLeft={2}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              component="a"
              target="_blank"
              rel="noopener"
              href={`mailto:?subject=I%20found%20this%20job%20on%20Seasoned%20Force&body=I%20think%20you%20would%20like%20this%20job%20on%20Seasoned%20Force:%20${encodeURIComponent(window.location.href)}`}
            >
              Send to a friend
            </Button>
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginY: 4 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          {/* <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Who we are
            </Typography>
            <Typography component={'p'}>
              We believe lorem ipsum dolor sit amet, consectetur adipiscing
              elit. Phasellus feugiat elit vitae enim lacinia semper. Cras nulla
              lectus, porttitor vitae urna iaculis, malesuada tincidunt lectus.
              Proin nec tellus sit amet massa auctor imperdiet id vitae diam.
              Aenean gravida est nec diam suscipit iaculis. Praesent urna velit,
              auctor nec turpis et, vehicula lobortis sem. Vivamus convallis mi
              sagittis eleifend laoreet. Praesent vitae venenatis enim. Nulla
              tincidunt felis et lectus rhoncus laoreet.
            </Typography>
          </Box> */}
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              What we’re looking for
            </Typography>
            {/* <Typography component={'p'}>
              Aenean gravida est nec diam suscipit iaculis. Praesent urna velit,
              auctor nec turpis et, vehicula lobortis sem. Vivamus convallis mi
              sagittis eleifend laoreet. Praesent vitae venenatis enim. Nulla
              tincidunt felis et lectus rhoncus laoreet.
            </Typography> */}
            <RichText object={job.description} />
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    fontWeight={700}
                    color="text.primary"
                    // sx={{ color: 'common.white' }}
                  >
                    Job details at a glance:
                  </Typography>
                  <JobMetric
                    icon={'🕒'}
                    label={'Job Type'}
                    value={camelCaseToWords(job.type)}
                  />

                  <JobMetric
                    icon={'📍'}
                    label={'Location'}
                    value={
                      job.isRemote ? 'Remote' : `${job.city}, ${job.state}`
                    }
                  />
                   <JobMetric
                    icon={'💲'}
                    label={'Pay'}
                    value={
                      job.maxCompensation ? `$${job.minCompensation} - $${job.maxCompensation} ${job.compStyle}` 
                      : 
                      `$${job.minCompensation} ${job.compStyle}`
                    }
                  />
                  <JobMetric
                    label={'Flexible Hours'}
                    value={job.isFlexible ? 'Flexible Hours' : 'Fixed Hours'}
                    icon={'🕒'}
                  />
                  <JobMetric
                    label="Work Environment"
                    value={job.isSocial ? 'Social' : 'Independant'}
                    icon={'👫'}
                  />

                  <JobMetric
                    label={'Physical Requirements'}
                    value={jobActivityLevelDisplay[job.activityLevel]}
                    icon={'💪'}
                  />
                </CardContent>
              </Box>
            </Grid>
            {!job.isRemote && (
              <Grid item xs={12} data-aos="fade-up">
                <Box component={Card}>
                  <CardContent>
                    {/* <Typography variant="h6" gutterBottom color="text.primary">
                      Job Location
                    </Typography> */}
                    <Box width="100%" height="200px">
                      <GoogleMap job={job} />
                    </Box>
                    {!!distance && (
                      <Typography variant="subtitle">
                        {distance.toFixed(1)} miles from you
                      </Typography>
                    )}
                    {/* <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    endIcon={
                      <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    See distance from me
                  </Button> */}
                  </CardContent>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;

const JobMetric = ({ icon, label, value }) => {
  return (
    <ListItem>
      {/* <ListItemAvatar>
        <Avatar
          sx={{
            backgroundColor: 'primary.main',
            color: 'primary.contrastText',
          }}
        >
          {icon}
        </Avatar>
      </ListItemAvatar> */}
      <ListItemText primary={value} secondary={label} />
    </ListItem>
  );
};
