import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

export default function GoogleMaps({
  handleSelect,
  helperText = '',
  error = null,
  label = 'Add a location',
  disabled = false,
  address = '',
}) {
  const places = useMapsLibrary('places');
  const [inputValue, setInputValue] = React.useState(address);
  const [options, setOptions] = React.useState([]);
  const [autocompleteService, setAutocompleteService] = React.useState(null);
  const [sessionToken, setSessionToken] = React.useState();

  React.useEffect(() => {
    if (!places) return;

    setAutocompleteService(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [places]);

  const fetchPredictions = React.useCallback(
    async (inputValue) => {
      if (!autocompleteService || !inputValue) {
        setOptions([]);
        return;
      }
      const request = { input: inputValue, sessionToken };
      const response = await autocompleteService.getPlacePredictions(request);
      setOptions(response.predictions);
    },
    [autocompleteService, sessionToken],
  );

  const onInputChange = React.useCallback(
    (event) => {
      const value = event?.target?.value || address || '';

      setInputValue(value);
      fetchPredictions(value);
    },
    [fetchPredictions],
  );

  const updateLocation = (object) => {
    if (!object) {
      handleSelect(null);
      return;
    }
    const placeId = object.place_id;
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ placeId }).then(({ results }) => {
      const result = results[0];

      result.lat = result.geometry.location.lat();
      result.lng = result.geometry.location.lng();

      const city = result.address_components.find((c) =>
        c.types.includes('locality'),
      );

      result.city = city.short_name || city.long_name;

      const state = result.address_components.find((c) =>
        c.types.includes('administrative_area_level_1'),
      );

      result.state = state.short_name || state.long_name;

      const country = result.address_components.find((c) =>
        c.types.includes('country'),
      );

      result.country = country.short_name || country.long_name;
      handleSelect(result);
    });
  };

  return (
    <Autocomplete
      sx={{ width: '100%' }}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.description
      }
      disabled={disabled}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      filterSelectedOptions
      value={inputValue}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setInputValue(newValue);
        updateLocation(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        onInputChange(event);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          label={label}
          fullWidth
          helperText={helperText}
        />
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length]),
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: 'text.secondary', mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
