import { EMAIL_REGEX } from '../../constants';

export const yupErrorText = (errors) => {
  if (typeof errors === 'string') {
    return errors;
  } else if (Array.isArray(errors)) {
    return errors.join(', ');
  } else {
    return 'Error';
  }
};

export const formatJobForSaving = (job) => {
  const formattedJob: any = {
    company: job.company,
    position: job.position,
    type: job.type,
    isFlexible: !!job.isFlexible,
    isSocial: !!job.isSocial,
    isRemote: !!job.isRemote,
    activityLevel: job.activityLevel,
    minCompensation: removeZerosFromAmountString(
      job.minCompensation.toString(),
    ),
    maxCompensation: removeZerosFromAmountString(
      job.maxCompensation.toString(),
    ),
    compStyle: job.compStyle,
    description: job.description,
    instructions: job.instructions,
    admin: { companyEmail: job.adminEmail }, // Assuming this is a constant value
  };

  // Check if emailOrUrl is an email or a URL
  const emailOrUrl = job.emailOrUrl;
  if (EMAIL_REGEX.test(emailOrUrl)) {
    formattedJob.applicationEmail = emailOrUrl;
  } else {
    formattedJob.applicationUrl = emailOrUrl;
  }

  // Check if location is provided
  if (job.isRemote === false && job.location) {
    formattedJob.location = job.location;
    formattedJob.lat = job.location.lat;
    formattedJob.lng = job.location.lng;
    formattedJob.city = job.location.city;
    formattedJob.state = job.location.state;
  }

  return formattedJob;
};

export const formatJobForEditing = (job) => {
  const formattedJob: any = {
    company: job.company,
    position: job.position,
    type: job.type,
    isFlexible: job.isFlexible,
    isSocial: job.isSocial,
    isRemote: job.isRemote,
    activityLevel: job.activityLevel,
    minCompensation: job.minCompensation,
    maxCompensation: job.maxCompensation,
    compStyle: job.compStyle,
    description: job.description,
    instructions: job.instructions,
    adminEmail: job.admin.companyEmail,
  };

  // Check if applicationEmail or applicationUrl is provided
  if (job.applicationEmail) {
    formattedJob.emailOrUrl = job.applicationEmail;
  } else {
    formattedJob.emailOrUrl = job.applicationUrl;
  }

  // Check if location is provided
  if (job.location) {
    formattedJob.location = job.location;
  }

  return formattedJob;
};

const removeZerosFromAmountString = (amount: string) => {
  amount = amount.replace(/,/g, '');
  if (amount.indexOf('.') !== -1) {
    const decimalPart = amount.split('.')[1];

    if (decimalPart === '00') {
      return amount.split('.')[0];
    }
  }

  return amount;
};
